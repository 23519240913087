
.title {
  font-size: 32px;
  font-weight: bold;
  display: block;

  &--big{
    font-size: 48px;
  }

  &--semi{
    font-size: 26px;
  }

  &--medium {
    font-size: 22px;
  }

  &--smaller {
    font-size: 1.5rem;
  }

  &--line{
    position: relative;
    margin-bottom: 30px;

    &:after{
      content: '';
      position: absolute;
      width: 50px;
      height: 2px;
      border-style: 6px;
      background: #aeb1bc;
      bottom: -15px;
      left: 0;
    }
  }
}
