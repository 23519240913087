$text: #0f0f0f;
$primary: #0E2358;
$secondary: #00113c;
$hover_primary: #1a326e;
$hover_secondary: #1e2e58;
$danger: #FF0000;
$blue: #0AADD4;
$line: #D7D7D7;
$yellow: #FFCC26;
$black: rgba(0, 0, 0, .1);
$black-2: rgba(0, 0, 0, .2);
$black-3: rgba(0, 0, 0, .3);
$black-4: rgba(0, 0, 0, .4);
$white: rgba(255, 255, 255, .1);
$white-2: rgba(255, 255, 255, .2);
$white-3: rgba(255, 255, 255, .3);
$white-4: rgba(255, 255, 255, .4);
$white-8: rgba(255, 255, 255, .8);


.color--primary{
  color: $primary;
}

.color--secondary{
  color: $secondary;
}

.color--text{
  color: $text;
}

.bg--primary{
  background: $primary;
}

.bg--secondary{
  background: $secondary;
}

.color--white{
  color: #fff;
}
