@media (max-width: 768px) {
  .page-link {
    font-size: 11px;
  }

  .tags__link {
    font-size: 12px;
  }

  .tags--gallery .tags__link {
    padding-right: 18px;
    padding-left: 18px;
  }

  .box__video {
    width: 30px;
  }

  .box--video .box__title {
    font-size: 12px;
  }

  .title {
    font-size: 1.5rem;

    &--medium {
      font-size: 1.3rem;
    }
  }

  .news-top__small {
    font-size: 14px;
  }

  .text-content {
    font-size: 14px;
  }

  .h1,
  h1 {
    font-size: 1.5rem;
  }

  .h2,
  h2 {
    font-size: 1.4rem;
  }

  .h3,
  h3 {
    font-size: 1.3rem;
  }

  .h4,
  h4 {
    font-size: 1.2rem;
  }

  .h5,
  h5 {
    font-size: 1.1rem;
  }

  .h6,
  h6 {
    font-size: 1rem;
  }

  .news-top__dates {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 5px;
    width: 100%;

    span {
      margin: 0;
      font-size: 8px;

      &:first-child {
        margin-right: 10px;
      }
    }
  }

  .form-control {
    font-size: 14px;
  }

  .write-comment {
    input.form-control {
      margin-bottom: 15px;
    }
  }

  .content__banner--video iframe {
    height: 40vh;
  }

  .comment__text{
    font-size: 12px;
  }

  .comment__button{
    font-size: 10px;
  }

  .page-title__title{
    font-size: 20px;
  }

  .page-title{
    .bi{
      width: 24px;
      height: 24px;
    }
  }

  .contact-road{
    text-align: left;
  }

  .title--smaller{
    font-size: 1.2rem;
  }

  p{
    font-size: 14px;
  }

  .kunye-table{
    font-size: 14px;
  }

  .news-list__link--writer .news-list__img{
    width: 60px;
  }

  .news-list__link--writer .news-list__title{
    width: calc(100% - 60px);
  }
}
