@media (max-width:1200px) {
  .stock-list--wide .stock-list__title {
    font-size: 11px;
  }

  .stock-list--wide .stock-list__current {
    font-size: 11px;
  }

  .box__title {
    font-size: 13px;
  }

  .menu__link {
    font-size: 17px;
  }

  .home-top__left {
    width: 630px;
  }

  .home-top__right {
    width: calc(100% - 650px);
  }

  .home-top__right .news-list {
    margin-top: 21px;
  }

  .news-list__img {
    width: 100px;
  }

  .news-list__title {
    width: calc(100% - 120px);
    font-size: 13px;
  }

  .home-slider .swiper-pagination-bullet {
    width: 24px;
    height: 24px;
    font-size: 12px;
    line-height: 24px;
  }

  .slider-detail {
    font-size: 18px;
  }

  .news-list__item {
    padding: 11px 10px;
  }

  .stock-item__icon {
    width: 30px;
    height: 30px;

    img {
      height: 18px;
    }
  }

  .stock-item__content {
    width: calc(100% - 38px);
    font-size: 14px;
  }

  .stock-item__content strong {
    font-size: 12px;
  }

  .swiper-button-next:after,
  .swiper-button-prev:after {
    font-size: 28px;
  }

  .stock-list__title {
    font-size: 14px;
  }

  .stock-list__current {
    font-size: 12px;
  }

  .stock-list__state {
    font-size: 12px;
  }

  .home-sport__slider {
    width: 600px;
  }

  .home-sport__table {
    width: calc(100% - 620px);
  }

  .point-table__table td,
  .point-table__table th {
    padding: 4px 5px;
  }

  .point-table__table {
    font-size: 11px;
  }

  .point-table__title {
    font-size: 12px;

    img {
      width: 20px;
      height: 20px;
    }
  }

  .point-table__select {
    font-size: 12px;
  }

  .copyright,
  .footer-menu__link {
    font-size: 12px;
  }

  .footer__bottom ul a {
    font-size: 12px;
  }

  .social-card__title {
    font-size: 13px;
  }

  .social-card__text {
    font-size: 11px;
  }

  .social-card__top img {
    max-width: 24px;
  }

  .content__left {
    width: 630px;
  }

  .content__right {
    width: calc(100% - 650px);
  }

  .title {
    font-size: 26px;

    &--medium {
      font-size: 22px;
    }
  }

  .mt50 {
    margin-top: 30px;
  }

  .box__title {
    padding: 10px;
    font-size: 12px;
  }

  .box__badge {
    font-size: 10px;

    .bi {
      width: 14px;
      height: 14px;
    }
  }

  .mt-5 {
    margin-top: 2rem !important;
  }

  .mb-5 {
    margin-bottom: 2rem !important;
  }

  .my-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .whatsapp-big{
    small{
      font-size: 15px;
    }

    strong{
      font-size: 26px;
    }
  }

  .top-menu__badge, .top-menu__link{
    font-size: 14px;
  }

  .top-menu__item{
    padding-right: 14px;
    margin-right: 14px;

    &:after{
      right: -2px;
    }
  }

  .writer-small__img{
    width: 40px;
    height: 40px;
  }

  .writer-small__content{
    padding-left: 8px;
    width: calc(100% - 40px);
  }

  .writer-small{
    .btn{
      font-size: 12px;
      padding: 5px 10px;
    }
  }
}
