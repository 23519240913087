@media (max-width: 992px) {
  body {
    padding-top: 54px;
    background: #fff;
  }

  .btn-mobil {
    display: block;
  }

  .content {
    &__left {
      width: 100%;
    }

    &__right {
      margin-top: 30px;
      width: 100%;
    }
  }

  .top-stocks {
    // overflow-x: scroll;
    padding: 10px 15px;
    border-bottom: 1px solid #eee;

    .swiper-slide {
      width: auto;
    }

    .stock-list {
      // flex-wrap: nowrap;
      // padding-bottom: 10px;
      // white-space: nowrap;
      &__item {
        // margin-right: 20px;
        display: inline-block;
        width: auto;
        line-height: 1.2;
        // &:last-child {
        //   padding-right: 10px;
        // }
      }
    }
  }

  .logo {
    position: relative;
    top: auto;
    right: auto;
    left: auto;
    width: auto;
  }

  .header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 30;
    margin-top: 0;
    width: 100%;
  }

  .header__top .container {
    height: 54px;
  }

  .btn-mobil {
    top: 4px;
  }

  .latest {
    background: #FC3B38;

    &__title {
      display: inline-block;
      width: 50px;
      text-align: center;
      font-size: 18px;
      line-height: 1;

      small {
        display: block;
        font-size: 12px;
      }
    }

    &__marquee {
      position: relative;
      top: 4px;
      width: calc(100% - 65px);

      &:after,
      &:before {
        display: none;
      }
    }
  }

  .marquee__item {
    display: inline-block;
    padding: 0 10px;
    width: 110px;
    height: 32px;
    border-right: 1px solid $white-2;
    text-align: center;
    white-space: normal;
    font-weight: 600;
    font-size: 9px;
    line-height: 1.1;
  }

  .slider-detail {
    padding: 10px;
    font-size: 15px;
  }

  .home-slider {
    border-bottom: 1px solid #eee;
    box-shadow: none;

    .swiper-pagination {}
  }
  // .subhead {
  //   .container {
  //     overflow-x: scroll;
  //   }
  //
  //   &__scroll {
  //     display: flex;
  //     flex-wrap: nowrap;
  //     padding-bottom: 15px;
  //     white-space: nowrap;
  //   }
  //
  //   .box {
  //     display: block;
  //     margin-right: 10px;
  //     width: auto;
  //
  //     &__link {
  //       width: 166px;
  //       white-space: normal;
  //     }
  //   }
  // }
  .subhead-mobile {
    margin-bottom: 15px;

    .swiper {
      padding: 0 15px 20px;
    }

    .swiper-pagination {
      bottom: 0;
    }
  }

  .news-list__number {
    width: 38px;
    font-size: 28px;
  }

  .news-list--numbers {
    .news-list__item {
      padding: 12.3px 5px;
    }

    .news-list__title {
      padding-left: 5px;
      width: calc(100% - 40px);
      font-size: 10px;
    }
  }

  .mobile-weather {
    padding-right: 15px;

    .swiper-wrapper {
      padding: 0 15px;
    }

    .swiper-slide {
      width: auto;
    }
    // overflow-x: scroll;
    // padding-left: 15px;
    //
    // &__scroll {
    //   display: flex;
    //   flex-wrap: nowrap;
    //   padding-bottom: 15px;
    //   white-space: nowrap;
    // }
  }

  .weather {
    display: inline-flex;
    flex-wrap: nowrap;
    // margin-right: 30px;
    &__location {
      display: block;
      font-weight: 400;
      font-size: 16px;
    }

    &__data {
      padding: 0 0 0 10px;
      width: auto;
      text-align: left;
      font-weight: bold;
      font-size: 20px;
      line-height: 1.2;
    }

    &:last-child {
      padding-right: 15px;
    }
  }

  .footer__top {
    padding: 10px;
    height: auto;
    text-align: center;
  }

  .footer-logo {
    position: relative;
    top: auto;
    right: auto;
    left: auto;
    display: inline-block;
    width: 120px;
  }

  .footer__middle {
    padding-top: 20px;
    padding-bottom: 0;
  }

  .footer-menu {
    margin-bottom: 30px;
    width: 48%;
  }

  .footer__bottom {
    padding: 15px 0;

    ul {
      li {
        margin: 5px 8px;
      }

      a {
        font-size: 10px;
      }
    }
  }

  .copyright {
    font-size: 9px;
  }

  .content__banner {
    img {
      box-shadow: none;
    }
  }

  .share-buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    border-bottom: 1px solid #eee;
  }

  .share-button {
    margin: 0;
    padding: 10px 0;
    width: 16.66666667%;
    height: auto;
    border-right: 1px solid #eee;
    border-radius: 0;
    background: none;
    text-align: center;

    .bi {
      top: 0;
    }
  }

  .share-button {
    .bi {
      width: 18px;
      height: 18px;
    }

    &--facebook {
      color: #303D83;
    }

    &--twitter {
      color: #6CDCFF;
    }

    &--linkedin {
      color: #303D83;
    }

    &--pinterest {
      color: #FF0000;
    }

    &--whatsapp {
      color: #28A745;
    }

    &--mail {
      color: #051048;
    }

    &--size {
      color: #363636;
    }
  }

  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 10px;
  }

  .search-wrapper .container {
    padding-right: 70px;
    padding-left: 0;
  }

  .search-btn {
    padding: 8px 16px;
    width: auto;
    height: auto;
    font-size: 10px;
    line-height: 1.5;
  }

  .search-input {
    width: calc(100% - 80px);
    height: 36px;
    font-size: 12px;
  }

  .search-wrapper__close {
    right: 0;
  }

  .search-wrapper .container {
    padding-right: 0;
  }

  .top-stocks {
    z-index: 10;
  }

  .top-menu__item {
    margin: 10px 0;
    width: 50%;
    border-bottom: 1px solid #eee;

    &:after {
      display: none;
    }
  }

  .top-menu__link {
    @include efekt(color);
    display: inline-block;
    padding: 10px 0;
    color: #000;

    img {
      margin-right: 6px;
      width: 28px;
    }

    &:hover {
      color: $blue;
    }
  }

  .top-menu__all {
    position: relative;
    top: auto;
    right: auto;
    display: block;
    margin-top: 10px;
    width: 100%;
    transform: none;
  }

  .top-menu__badge {
    display: block;
    margin-right: 0;
    margin-bottom: 5px;
    width: 100%;
    font-size: 12px;
  }

  .top-menu {
    padding: 0 15px;
  }

  .menu-wrapper .search-wrapper {
    position: relative;
    top: 0;
    top: auto;
    right: auto;
    display: block;
  }

  .search-wrapper {
    padding: 5px 15px;
    border-bottom: 1px solid #eee;
    background: #fff;
    top: 54px;
  }

  .search-input {
    background: #fff;
    color: #747474;
    text-overflow: ellipsis;

    &::placeholder {
      color: #747474;
    }

    &:focus,
    &:hover {
      color: #747474;

      &::placeholder {
        color: #747474;
      }
    }
  }

  .menu-wrapper {
    position: fixed;
    left: 0;
    top: 0;
    padding: 54px 0 60px;
    height: 100%;
    background: #fff;

    .search-wrapper__form {
      border-bottom: 1px solid #eee;
      padding: 10px 15px;
    }

    .container {
      padding-right: 0;
      padding-left: 0;
      height: calc(100% - 40px);
      overflow-y: scroll;
    }

    &__bottom {
      position: absolute;
      bottom: 0;
      left: 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 100%;
      background: #dee0e4;
    }

    .social {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      width: calc(100% - 120px);
    }

    &__kunye {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      width: 120px;
      background: #c6c9d2;
      color: #000;
      text-align: center;
      text-decoration: none;
      font-weight: bold;
      font-size: 13px;

      img {
        margin-right: 6px;
        width: 20px;
      }

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .social {
    &__link {
      @include efekt(color);
      display: inline-block;
      margin: 0 6px;
      color: #000;

      &:hover {
        color: $blue;
      }
    }
  }

  .page-flex {
    flex-direction: column-reverse;
  }

  .left-menu {
    width: 100%;
  }

  .page-content {
    width: 100%;
    margin-bottom: 30px;
  }
}
