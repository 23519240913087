@import 'colors', 'mixin', 'global', 'hamburgers', 'bootstrap', 'swiper', 'typography', 'buttons';

#__bs_notify__ {
  display: none !important;
}

.mobil-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 996;
  display: none;
  width: 100%;
  height: 100%;
  background: $black;

  &.active {
    display: block;
  }
}

.mobil {
  display: none;

  &.active {
    display: block;
  }
}

.header {
  position: relative;
  z-index: 30;
  margin-top: 22px;

  &__search {
    @include efekt(opacity);
    width: 30px;
    color: #fff;

    .bi {
      width: 26px;
      height: 26px;
    }

    &:hover {
      opacity: 0.7;
    }
  }

  &__top {
    position: relative;
    z-index: 32;
    background: $secondary;

    .container {
      @extend .flex;
      position: relative;
      justify-content: space-between;
      height: 60px;
    }
  }

  &__bottom {
    position: relative;
    z-index: 30;
    background: #fff;
  }
}

.logo {
  position: absolute;
  top: -13px;
  right: 0;
  left: 0;
  margin: 0 auto;
  width: 271px;
}

.hamburger {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  color: #fff;

  &__text {
    margin-right: 8px;
    font-weight: 600;
    font-size: 14px;
  }
}

.menu {
  font-family: $inter;

  &__list {
    @extend .flex;
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  &__item {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 62px;
  }

  &__link {
    @include efekt(opacity);
    color: #111;
    text-decoration: none;
    font-weight: bold;
    font-size: 20px;

    &--son {
      color: $danger;
    }

    &--tech {
      color: $blue;
    }

    &:hover {
      opacity: 0.7;
    }
  }
}

.latest {
  background: #010C30;
  color: #fff;

  .container {
    @extend .flex;
    height: 48px;
  }

  &__title {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    width: 110px;
    color: #fff;
    font-weight: bold;
    font-size: 12px;

    .dot {
      margin-right: 6px;
    }
  }

  &__marquee {
    position: relative;
    width: calc(100% - 160px);
    font-weight: 500;
    font-size: 13px;

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 4;
      width: 130px;
      height: 100%;
      background: linear-gradient(to right, #010C30, transparent);
      content: '';
    }

    &:after {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 4;
      width: 130px;
      height: 100%;
      background: linear-gradient(to left, #010C30, transparent);
      content: '';
    }
  }

  &__link {
    @include efekt(opacity);
    width: 50px;
    color: #fff;
    text-align: right;
    text-decoration: underline;
    font-weight: bold;
    font-size: 13px;

    &:hover {
      opacity: 0.7;
    }
  }
}

.dot {
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: $yellow;
}

.blink {
  animation: blink 1s linear infinite;
}
@keyframes blink {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.marquee {
  &__item {
    margin: 0 6px;
    color: #fff;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.home-slider {
  @extend .bxs;
  position: relative;
  background: #fff;

  .swiper-button-next:after,
  .swiper-button-prev:after {
    @extend .efekt;
    color: #fff;
    text-shadow: 1px 1px 1px #000;
    opacity: 0.8;
  }

  .swiper-button-next,
  .swiper-button-prev {
    &:hover {
      &:after {
        text-shadow: 1px 1px 3px #000;
        opacity: 1;
      }
    }
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .swiper-pagination {
    @extend .flex;
    position: relative !important;
    bottom: 0 !important;
    padding: 6px;
    background: #fff;
  }

  .swiper-pagination-bullet {
    margin: 0 !important;
    width: 30px;
    height: 30px;
    background: #f3f4f6;
    color: #000;
    text-align: center;
    font-weight: bold;
    font-size: 14px;
    line-height: 30px;
    opacity: 1;

    &.swiper-pagination-bullet-active,
    &:Hover {
      background: rgba($danger, .8);
      color: #fff;
    }
  }

  &--sport {
    background: transparent;

    .swiper-pagination {
      position: absolute !important;
      justify-content: center;
      padding: 15px !important;
      background: transparent !important;
    }

    .swiper-pagination-bullet {
      margin: 0 4px !important;
      width: 30px;
      height: 30px;
      background: #f3f4f6;
      color: #000;
      text-align: center;
      font-weight: bold;
      font-size: 14px;
      line-height: 30px;
      opacity: 1;

      &.swiper-pagination-bullet-active,
      &:Hover {
        background: #14B21F;
        color: #fff;
      }
    }

    .swiper-pagination-bullet-active {
      background: #14B21F !important;
      color: #fff !important;
    }

    .slider-detail {
      padding-top: 70px;
      padding-bottom: 70px;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.0001) 0%, #000000 100%);
    }
  }
}

.box {
  @extend .bxsh;
  @include efekt(box-shadow);
  position: relative;
  background: #fff;

  &__badge {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 8px;
    background: rgba(#000000, 0.7);
    color: #fff;
    font-weight: 600;
    font-size: 11px;

    .bi {
      margin-right: 4px;
    }
  }

  &__link {
    display: block;
    color: inherit;
    text-decoration: none;

    &:Hover {
      .box__title {
        text-decoration: underline;
      }
    }
  }

  &__img {
    position: relative;

    img {
      width: 100%;
    }
  }

  &__video {
    @extend .dikeyAbsolute;
    right: 0;
    left: 0;
    margin: 0 auto;
    width: 50px;
  }

  &__title {
    display: block;
    padding: 10px 15px;
    color: #000;
    word-break: break-word;
  }

  &__info {
    @extend .flex;
    padding: 5px 10px;
    color: #7C849A;
    text-decoration: none !important;
    font-weight: 600;
    font-size: 10px;

    .bi {
      width: 12px;
      height: 12px;
    }
  }

  &--video {
    text-align: center;

    .box__title {
      font-size: 14px;
    }
  }
}

.home-top {
  .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__left {
    width: 730px;
  }

  &__right {
    width: calc(100% - 760px);

    .news-list {
      margin-top: 23px;
    }
  }

  &__stock {
    @extend .bxs;
    @extend .flex;
    margin-top: 20px;
    padding: 10px;
    background: #fff;
  }
}

.whatsapp-big {
  @extend .bxsh;
  @extend .efekt;
  position: relative;
  display: block;
  padding-top: 17px;
  padding-left: 50px;
  height: 84px;
  background: linear-gradient(270deg, #2DD942 0%, #14B21F 100%);
  color: #fff;
  text-align: center;
  text-decoration: none;
  font-weight: bold;
  line-height: 1.2;

  .bi {
    @extend .dikeyAbsolute;
    left: 10px;
    width: 48px;
    height: 48px;
  }

  small {
    display: block;
    color: #015024;
    font-size: 18px;
  }

  strong {
    font-size: 28px;
  }

  &:hover {
    transform: scale(1.02);
  }
}

.news-list {
  @extend .bxs;
  margin-bottom: 0;
  padding: 0;
  list-style-type: none;

  &__item {
    position: relative;
    position: relative;
    padding: 10px;
    border-bottom: 1px solid $line;
    background: #fff;

    &:last-child {
      border: none;
    }

    &:hover {
      background: linear-gradient(270deg, #FE6D69 0%, #FC3B38 100%);

      .news-list__title {
        color: #fff;

        small{
          color: #fff;
        }
      }

      .news-list__text {
        color: #fff;
      }
    }
  }

  &__link {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;

    &--writer {
      .news-list__img {
        width: 80px;
      }

      .news-list__title {
        width: calc(100% - 80px);
        padding: 0 0 0 10px;

        small{
          display: block;
          color: #777;
        }
      }
    }
  }

  &__title {
    padding-left: 10px;
    width: calc(100% - 150px);
    color: #000;
    font-weight: 600;
    font-size: 14px;
  }

  &__img {
    width: 120px;
  }

  &__number {
    display: inline-block;
    width: 70px;
    color: #000;
    font-weight: bold;
    font-size: 48px;
    opacity: 0.2;
  }

  &__writer {
    padding-left: 10px;
    width: calc(100% - 60px);
  }

  &__profile {
    width: 60px;
  }

  &__text {
    display: block;
    color: #8e8e8e;
    font-size: 12px;
  }

  &--single {
    margin-top: 0;
  }

  &--numbers {
    .news-list__title {
      padding-left: 10px;
      width: calc(100% - 70px);
    }
  }

  &--writers {
    .news-list__title {
      padding-left: 0;
      width: 100%;
    }
  }
}

.stock-item {
  @extend .flex;
  margin: 0 5px;
  padding: 8px;
  width: calc(25% - 10px);

  &--dolar {
    background: #f0f7e9;
    color: #67AC1E;

    .stock-item__content {
      strong {
        color: #3C680E;
      }
    }

    .stock-item__icon {
      background: #67AC1E;
    }
  }

  &--euro {
    background: #fff3ed;
    color: #FF8743;

    .stock-item__content {
      strong {
        color: #AB4912;
      }
    }

    .stock-item__icon {
      background: #FF8743;
    }
  }

  &--gold {
    background: #fff7eb;
    color: #FBB034;

    .stock-item__content {
      strong {
        color: #B87A14;
      }
    }

    .stock-item__icon {
      background: #FBB034;
    }
  }

  &--bist {
    background: #e6f5f9;
    color: #029FC3;

    .stock-item__content {
      strong {
        color: #05748D;
      }
    }

    .stock-item__icon {
      background: #029FC3;
    }
  }

  &__icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 5px;

    img {
      height: 24px;
    }
  }

  &__content {
    padding-right: 6px;
    padding-left: 6px;
    width: calc(100% - 48px);
    font-size: 16px;

    small {
      font-weight: 500;
    }

    strong {
      display: block;
      font-weight: bold;
      font-size: 14px;
    }
  }

  &__stat {
    position: relative;
    top: 4px;
    width: 8px;

    &--up {
      animation: up 2s ease-in-out infinite;
    }

    &--down {
      animation: down 2s ease-in-out infinite;
    }
  }
}
@keyframes up {
  0% {
    top: 4px;
    opacity: 0;
  }

  50% {
    top: -4px;
    opacity: 1;
  }

  100% {
    top: 4px;
    opacity: 0;
  }
}
@keyframes down {
  0% {
    top: 4px;
    opacity: 0;
  }

  50% {
    top: -4px;
    opacity: 1;
  }

  100% {
    top: 4px;
    opacity: 0;
  }
}

.home-stock {
  &__flex {
    @extend .flex;
    @extend .bxs;
    padding: 25px 15px;
    background: #fff;
  }

  .weather {
    width: 240px;
  }

  .stock-list {
    width: calc(100% - 290px);
  }
}

.stock--down {
  color: #C90C0F;
}

.stock--up {
  color: #0C9900;
}

.stock-list {
  @extend .flex;
  margin: 0;
  padding: 0;
  list-style: none;
  font-family: $inter;

  &__item {
    text-align: center;

    img {
      position: relative;
      top: -1px;
    }
  }

  &__title {
    display: block;
    color: #646464;
    font-size: 14px;
  }

  &__current {
    color: #000;
    font-size: 16px;

    .bi {
      width: 12px;
      height: 12px;
    }
  }

  &__state {
    display: inline-block;
    margin-left: 2px;
    font-size: 16px;
  }

  &--wide {
    .stock-list__item {
      width: 8%;
      color: #0f0f0f;
      text-align: left;

      .bi {
        width: 10px;
        height: 10px;
      }
    }

    .stock-list__title {
      font-weight: bold;
      font-size: 12px;
    }

    .stock-list__current {
      color: #666;
      font-size: 12px;
    }

    .stock-list__state {
      font-size: 10px;
    }
  }
}

.weather {
  @extend .flex;

  &__icon {
    width: 40px;
    height: 40px;
  }

  &__data {
    padding: 0 15px;
    width: calc(100% - 160px);
    color: #000;
    text-align: center;
    font-weight: 400;
    font-size: 36px;
    font-family: $inter;
  }

  &__select {
    @include efekt(background);
    padding: 0 5px;
    width: 120px;
    height: 38px;
    outline: transparent;
    border: none;
    border-radius: 5px;
    background: #ECECEC;
    text-overflow: ellipsis;
    font-weight: 500;
    font-size: 14px;

    &:hover {
      background: #dadada;
    }
  }
}

.slider-link {
  position: relative;
  display: block;
}

.slider-detail {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 4;
  padding: 15px;
  background: rgba(#000000, 0.7);
  color: #fff;
  font-weight: bold;
  font-size: 22px;

  &:hover {
    text-decoration: underline;
  }
}

.home-sport {
  &__card {
    background: #036C31;
  }

  &__top {
    @extend .flex;
    padding: 15px;
    background: #015024;
  }

  &__flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 15px;
  }

  &__title {
    @extend .flex;

    img {
      margin-right: 15px;
      padding-right: 15px;
      width: 170px;
      border-right: 1px solid #18BC58;
    }
  }

  &__badge {
    display: inline-block;
    padding: 8px 20px;
    border-radius: 5px;
    background: #036C31;
    color: #fff;
    text-decoration: none;
    font-weight: 600;
  }

  &__nav {
    @extend .flex;
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      display: inline-block;
      margin-left: 15px;
    }

    a {
      color: #fff;
      text-decoration: none;
      font-weight: 600;
      font-size: 0.9rem;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__slider {
    width: 690px;
  }

  &__table {
    width: calc(100% - 720px);
  }

  &__news {
    padding: 15px;
  }
}

.point-table {
  @extend .bxs;
  height: 100%;
  background: #fff;

  &__top {
    @extend .flex;
    padding: 10px;
  }

  &__title {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    color: #015024;
    font-size: 14px;

    img {
      margin-right: 6px;
    }
  }

  &__select {
    outline: transparent;
    border: none;
    background: #fff;
    color: #4F9D5A;
    font-weight: 600;
    font-size: 0.9rem;

    &:Hover {
      color: #2c7d38;
    }
  }

  &__table {
    width: 100%;
    border-collapse: collapse;
    font-weight: 600;
    font-size: 12px;

    td,
    th {
      padding: 5.75px 7px;
      border: 1px solid #ECECEC;
      text-align: center;
    }

    tr {
      td,
      th {
        &:first-child {
          overflow: hidden;
          max-width: 130px;
          text-align: left;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }

  &--simple {
    .point-table__top {
      background: #036C31;
    }

    .point-table__title {
      color: #fff;
    }

    .point-table__select {
      background: transparent;
      color: #fff;

      &:hover {
        opacity: 0.8;
      }
    }

    th {
      color: #D9D9D9;
    }

    td,
    th {
      padding: 7px 5px;
      border-right: none;
      border-left: none;
    }

    tr {
      td,
      th {
        &:first-child {
          overflow: visible;
          max-width: 100%;
          text-align: center;
          text-overflow: inherit;
          white-space: norma;
        }
      }
    }

    tr {
      td,
      th {
        &:nth-of-type(2) {
          overflow: hidden;
          max-width: 130px;
          text-align: left;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
}

.home-social {
  margin: 50px 0;

  &__card {
    @extend .bxs;
    background: #fff;
  }

  &__top {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background: #F4F7F9;

    .home-sport__badge {
      background: #2F2F30;
    }
  }

  &__flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 30px 30px 10px;
  }

  &__left {
    width: 323px;
  }

  &__right {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: calc(100% - 343px);
  }
}

.social-card {
  margin-bottom: 20px;
  padding: 15px;
  color: #fff;

  &__title {
    display: block;
    font-size: 18px;

    small {
      display: block;
      font-weight: bold;
      font-size: 16px;
    }
  }

  &__btn {
    @include efekt(opacity);
    display: inline-block;
    padding: 7px 32px;
    border-radius: 5px;
    background: #fff;
    text-decoration: none;
    font-weight: 700;
    font-size: 12px;

    &:hover {
      opacity: 0.9;
    }
  }

  &__top {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 15px;

    img {
      margin-right: 15px;
    }
  }

  &__text {
    margin: 0;
    padding: 0;
    font-size: 13px;
  }

  &--big {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 315px;
    background: linear-gradient(45deg, #FAD829 0%, #F93106 37.49%, #F6310A 49.9%, #7C38AF 100%);
    text-align: center;

    .social-card__title {
      margin-top: 10px;
      margin-bottom: 20px;
      font-size: 26px;
    }

    .social-card__btn {
      color: #97368A;
    }
  }

  &--facebook {
    width: calc(50% - 10px);
    height: 144px;
    background: #3B5993;

    .social-card__btn {
      color: #3B5998;
    }
  }

  &--twitter {
    width: calc(50% - 10px);
    height: 144px;
    background: #1DA1F2;

    .social-card__btn {
      color: #1DA1F2;
    }
  }

  &--youtube {
    width: 42%;
    height: 150px;
    background: #F42705;

    .social-card__btn {
      color: #F42705;
    }
  }

  &--app {
    width: calc(58% - 20px);
    height: 150px;
    background: #2F2F30;
  }
}

.app-icon {
  @include efekt(box-shadow);
  display: inline-block;
  width: 48%;
  border-radius: 6px;

  img {
    width: 100%;
  }

  &:hover {
    box-shadow: 0 0 0 4px rgba(#036c31, .3);
  }
}

.footer {
  background: #0E2358;

  &__top {
    position: relative;
    height: 34px;
    background: #00113C;
  }

  &__middle {
    padding: 40px 0;

    .container {
      @extend .flex;
    }
  }

  &__bottom {
    padding: 25px 0;
    border-top: 1px solid #274797;
    border-bottom: 1px solid #274797;

    ul {
      @extend .flex;
      justify-content: center;
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        margin: 0 15px;
      }

      a {
        color: #fff;
        text-decoration: none;
        font-weight: 500;
        font-size: 14px;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.footer-logo {
  position: absolute;
  top: -28px;
  right: 0;
  left: 0;
  margin: 0 auto;
  width: 271px;
}

.container {}

.footer-menu {
  width: 17%;

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__item {
    margin: 3px 0;
  }

  &__link {
    display: inline-block;
    padding: 3px 0;
    color: #fff;
    text-decoration: none;
    font-weight: 500;
    font-size: 14px;

    &:Hover {
      text-decoration: underline;
    }
  }
}

.copyright {
  padding: 20px 0;
  color: #fff;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
}

.top-stocks {
  position: relative;
  z-index: 31;
  padding: 10px;
  background: #fff;

  .stock-list {
    margin-right: auto;
    margin-left: auto;
    max-width: 100%;
    width: 1400px;
  }
}

.breadcrumb {
  margin: 0;
  padding: 0;
  background: none;
  font-weight: 500;
  font-size: 12px;

  &__link {
    color: #666;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &__divider {
    margin: 0 6px;
    color: #000;
  }

  &__item {}
}

.news-top {
  @extend .flex;

  &__dates {
    color: #9ba0a8;
    font-weight: 500;
    font-size: 12px;

    span {
      display: inline-block;
      margin-left: 15px;
    }
  }

  &__title {
    margin-bottom: 10px;
  }

  &__small {
    color: #3E4959;
    font-weight: 500;
    font-size: 16px;
  }
}

.text-content {
  font-family: $inter;
  line-height: 1.6;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: bold;
    line-height: 1.2;
  }

  a {
    color: #000;

    &:Hover {
      opacity: 0.7;
    }
  }
}

.content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &__banner {
    position: relative;
    margin-bottom: 15px;

    img {
      @extend .bxs;
      width: 100%;
    }

    &--video {
      iframe {
        width: 100%;
        height: 400px;
      }
    }

    .box__info {
      padding: 10px 0;
    }
  }

  &__left {
    width: 730px;
  }

  &__right {
    width: calc(100% - 770px);
  }

  &__tools {
    @extend .flex;
  }

  &__text {}

  &__img {
    position: relative;
    margin: 20px 0;

    img {
      width: 100%;
    }

    figcaption {
      padding: 10px;
      background: #DFDFDF;
      text-align: center;
    }
  }

  &--sticky {
    align-items: flex-start;

    .content__right {
      position: sticky;
      top: 15px;
    }
  }
}

.gallery-paging {
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 6px 10px;
  background: rgba(#000000, 0.8);
  color: #fff;
  font-weight: 600;
  font-size: 14px;
}

.share-buttons {}

.font-buttons {
  @extend .flex;
}

.font-btn {
  @include efekt(background);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background: #051048;
  color: #fff;
  font-weight: 500;
  font-size: 15px;
  font-family: $inter;

  &:first-child {
    border-radius: 0;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background: #333C6A;
    font-size: 13px;
  }

  sup {
    position: relative;
    top: -4px;
    font-size: 12px;
  }

  &:hover {
    background: #36458e;
  }
}

.detail-page {
  background: #f8f8f8;
}

.tags {
  @extend .flex;
  justify-content: flex-start;
  margin: 0;
  padding: 0;
  list-style: none;

  &__item {
    margin-right: 8px;
    margin-bottom: 8px;
  }

  &__link {
    @extend .efekt;
    display: inline-block;
    padding: 8px 18px;
    border-radius: 32px;
    background: #ececec;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    color: #808080;
    text-decoration: none;
    font-weight: 500;
    font-size: 14px;

    &:hover {
      background: $primary;
      color: #fff;
    }
  }

  &--gallery {
    .tags__link {
      padding-right: 24px;
      padding-left: 24px;
      background: #7c859a;
      box-shadow: none;
      color: #fff;

      &:hover {
        background: $primary;
      }
    }
  }
}

.write-comment {
  @extend .bxs;
  padding: 15px;
  background: #fff;

  &__title {
    font-weight: 900;
    font-size: 16px;

    small {
      font-weight: 400;
      font-size: 16px;
    }
  }

  &__btn {
    @extend .efekt;
    display: block;
    padding: 0.375rem 0.75rem;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    outline: transparent;
    border: 1px solid $blue;
    background: $blue;
    color: #fff;
    text-align: center;
    text-decoration: none;
    font-weight: 600;
    font-size: 1rem;
    cursor: pointer;

    &:hover {
      border-color: $primary;
      background: $primary;
      color: #fff;
    }
  }
}

.form-control {
  border-radius: 0;
}

.comment-list {
  @extend .bxs;
  padding: 15px;
  background: #fff;
}

.comment {
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #eee;

  &__title {
    display: block;
    margin-bottom: 5px;
    color: #C51D13;
    font-weight: bold;
    font-size: 14px;

    small {
      color: #bbbbbb;
      font-weight: 400;
      font-size: 14px;
    }
  }

  &__text {
    display: block;
    margin-bottom: 10px;
    font-size: 14px;
  }

  &__buttons {
    @extend .flex;
    justify-content: flex-start;
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      margin-right: 14px;
    }
  }

  &__button {
    @include efekt(color);
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    color: #888787;
    font-weight: 600;
    font-size: 12px;

    svg {
      margin-right: 4px;
    }

    path {
      @include efekt(fill);
    }

    &:hover {
      color: $primary;

      path {
        fill: $primary;
      }
    }
  }

  &--reply {
    margin-left: auto;
  }
}

.level--1 {
  width: 95%;
}

.level--2 {
  width: 90%;
}

.footer-app {
  margin-top: 160px;
  background: #B2F0FF;

  .container {
    @extend .flex;
    position: relative;
    padding-top: 70px;
    padding-bottom: 70px;
    padding-left: 290px;
  }

  &__img {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 264px;

    img {
      width: 100%;
    }
  }

  &__text {
    width: calc(100% - 380px);
    color: $primary;

    strong {
      font-size: 18px;
    }
  }

  &__buttons {
    width: 350px;
  }
}

.share-button {
  @include efekt(opacity);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 2px;
  width: 26px;
  height: 26px;
  border-radius: 5px;
  color: #fff;
  text-decoration: none;
  font-weight: 600;
  font-size: 11px;

  .bi {
    top: 0;
  }

  &:hover {
    opacity: 0.7;
  }

  span {
    display: inline-block;
  }

  &--facebook {
    background: #303D83;
  }

  &--twitter {
    background: #6CDCFF;
  }

  &--linkedin {
    background: #303D83;
  }

  &--pinterest {
    background: #FF0000;
  }

  &--whatsapp {
    background: #28A745;
  }

  &--mail {
    background: #051048;
  }

  &--print {
    background: $secondary;
  }

  &--save {
    padding: 0 8px;
    width: auto;
    background: $blue;

    .bi {
      margin-right: 4px;
    }
  }
}

.page-link {
  text-decoration: none;
  font-weight: 600;
  font-size: 14px;
}

.box-spacing {
  display: block;
  min-width: 1px;
  width: 1px;
  white-space: nowrap;
}

.featured-news-slider {
  .swiper-pagination-bullet-active {
    background: #000 !important;
  }
}

.menu-backdrop,
.search-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 28;
  display: none;
  width: 100%;
  height: 100%;
  background: rgba(#000000, 0.4);
}

.search-wrapper {
  position: absolute;
  top: 122px;
  left: 0;
  z-index: 30;
  display: none;
  padding: 15px;
  width: 100%;
  background: #00113c;

  &__close {
    @extend .dikeyAbsolute;
    @include efekt(opacity);
    right: 15px;
    color: #fff;

    .bi {
      width: 28px;
      height: 28px;
    }

    &:hover {
      opacity: 0.6;
    }
  }

  &__form {
    @extend .flex;
  }

  .container {
    position: relative;
    padding-right: 160px;
  }
}

.search-input {
  @include efekt(color);
  width: calc(100% - 100px);
  height: 45px;
  outline: transparent;
  border: none;
  background: none;
  color: #fff;
  text-overflow: ellipsis;
  font-size: 1rem;

  &:focus,
  &:hover {
    &::placeholder {
      color: rgba(#ffffff, 0.8);
    }
  }

  &::placeholder {
    @include efekt(color);
    color: rgba(#ffffff, 0.5);
  }
}

.search-btn {
  padding: 0;
  width: 95px;
  height: 45px;
  text-align: center;
  line-height: 45px;
}

.menu-wrapper {
  position: absolute;
  top: 122px;
  left: 0;
  z-index: 30;
  display: none;
  padding: 15px;
  width: 100%;
  background: #00113c;
}

.top-menu {
  @extend .flex;
  position: relative;
  justify-content: flex-start;
  margin: 0;
  padding: 20px 0;
  border-bottom: 1px solid $white;
  list-style: none;

  &:last-child {
    border-bottom: none;
  }

  &__item {
    position: relative;
    display: inline-block;
    margin-right: 26px;
    padding-right: 16px;

    &:after {
      position: absolute;
      top: -2px;
      right: -7px;
      display: inline-block;
      width: 1px;
      height: 30px;
      background: $white;
      content: '';
      transform: rotate(30deg);
    }

    &:nth-last-of-type(1) {
      margin-right: 0;
      padding-right: 0;

      &:after {
        display: none;
      }
    }
  }

  &__link {
    @include efekt(opacity);
    color: #fff;
    text-decoration: none;
    font-weight: 600;
    font-size: 16px;

    &:hover {
      opacity: 0.7;
    }
  }

  &__badge {
    margin-right: 20px;
    padding: 8px 24px;
    color: #fff;
    text-align: center;
    font-weight: 600;
    font-size: 16px;

    &--1 {
      background: #649F3C;
    }

    &--2 {
      background: #7AB3B5;
    }

    &--3 {
      background: #9F3E6F;
    }
  }

  &__all {
    @extend .dikeyAbsolute;
    right: 0;

    .top-menu__link {
      font-size: 12px;
    }
  }

  &--content {
    .top-menu__item {
      &:nth-last-of-type(2) {
        margin-right: 0;
        padding-right: 0;

        &:after {
          display: none;
        }
      }
    }
  }
}

.left-menu {
  padding: 10px;
  width: 270px;
  border: 1px solid #c4c4c4;
  border-radius: 6px;
  background: #f1f1f1;

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__item {
    margin: 2px 0;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__divider {
    display: block;
    margin: 10px 0;
    border-top: 1px solid #c4c4c4;
  }

  &__link {
    @extend .flex;
    @include efekt(color);
    padding: 6px 0;
    color: #777;
    text-decoration: none;
    font-weight: 600;
    font-size: 14px;

    .bi {
      @extend .efekt;
      left: -8px;
      width: 22px;
      height: 22px;
      opacity: 0;
    }

    &.active,
    &:hover {
      color: #000;

      .bi {
        left: 0;
        opacity: 1;
      }
    }
  }
}

.page-content {
  width: calc(100% - 300px);
}

.page-title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;

  &__title {
    margin: 0;
    font-weight: bold;
    font-size: 24px;
  }

  .bi {
    margin-right: 10px;
    width: 34px;
    height: 34px;
  }
}

.map {
  iframe {
    max-width: 100%;
    max-height: 40vh;
    width: 100%;
    height: 450px;
  }
}

.contact-road {
  text-align: right;
}

.contact-box {
  font-weight: 600;

  .bi {
    margin-right: 6px;
    width: 20px;
    height: 20px;
    color: $blue;
  }

  a {
    @include efekt(color);
    color: $text;
    text-decoration: none;

    &:Hover {
      color: $blue;
    }
  }
}

.kunye-table {
  margin: 0;
  padding: 0;
  border: 1px solid #eee;
  border-radius: 5px;
  list-style: none;

  li {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 15px;

    &:nth-of-type(even) {
      background: #f4f4f4;
    }
  }

  &__title {
    position: relative;
    padding-right: 10px;
    width: 40%;
    font-weight: 600;

    &:after {
      position: absolute;
      right: 0;
      content: ':';
    }
  }

  &__text {
    width: 55%;
  }
}

.page-content {
  .form-control {
    height: 44px;
    font-weight: 600;
    font-size: 14px;
  }

  textarea.form-control {
    height: auto;
  }
}

.custom-file-input {
  font-weight: 600;
}

.custom-file-label::after {
  content: 'Yükle';
  font-weight: 600;
}

.writer {
  padding: 15px 10px;
  border-radius: 5px;
  border: 1px solid #eee;
  background: #fff;
  text-align: center;

  &__img {
    display: block;
    margin: 0 auto;
    padding: 8px;
    width: 90px;
    height: 90px;
    border: 1px solid #eee;
    border-radius: 50%;
    background: #fff;
    object-fit: cover;
  }

  &__title {
    display: block;
    margin: 15px 0;
    padding-bottom: 15px;
    border-bottom: 1px solid #eee;
    font-weight: bold;
  }

  &__latest {
    display: block;
    color: #3E4959;
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 4px;
  }

  .news-top__dates {
    display: block !important;
    margin-bottom: 20px;
    width: 100% !important;
    text-align: center !important;
  }

  .btn {
    font-size: 14px;
  }
}

.writer-small {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background: #fff;
  border: 1px solid #eee;
  border-radius: 5px;

  &__img {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    object-fit: cover;
  }

  &__content {
    padding-left: 10px;
    width: calc(100% - 70px);
  }

  &__title {
    font-size: 16px;
    font-weight: bold;
    color: #000;
    display: block;
    margin-bottom: 4px;
  }

  .btn {
    font-size: 14px;
    padding: 6px 14px;
  }
}
@import 'style1200', 'style992', 'style768', 'style576';
